import React from 'react'

import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as Containers from './Containers'

/**
 */
export const Default = ({ next }) => {
  const { loading } = Project.Remote.Queries.Entry.useContext()

  if (loading) {
    return <PageLoader>Loading Project</PageLoader>
  }

  return <Containers.InitCompleted next={next} />
}
