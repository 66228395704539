import React from 'react'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'
import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'

import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as InitStep from './InitStep'
import * as InputStep from './InputStep'
import * as ProgressStep from './ProgressStep'
import * as CompleteStep from './CompleteStep'

/**
 */
export const BaseFlow = createFlow([
  InitStep.Default,
  InputStep.Default,
  ProgressStep.Default,
  CompleteStep.Default,
])

/**
 */
export const Flow = ({ ...props }) => {
  const [projectEntry] = Project.Scope.Entry.useEntry()

  return (
    <Project.Remote.Queries.PreFormData.Loader
      components={{
        Loading: () => <PageLoader>...loading</PageLoader>,
      }}
    >
      <Project.Remote.Mutations.UpdateEntry.Initializer
        projectId={projectEntry.uuid}
      >
        <BaseFlow {...props} />
      </Project.Remote.Mutations.UpdateEntry.Initializer>
    </Project.Remote.Queries.PreFormData.Loader>
  )
}
