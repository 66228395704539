import React from 'react'

import * as Routes from '@arch-log/webapp.shared/Routes'
import * as Project from '@arch-log/webapp.modules/project.v2/components'
import * as Containers from './Containers'
import * as Layout from './Layout'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'
import { useAsync } from 'react-use'

/**
 */
const ProgressStep = ({ next, prev, context }) => {
  const [updateEntry] = Project.Remote.Mutations.UpdateEntry.useMutation()

  useAsync(async () => {
    try {
      await updateEntry({
        variables: {
          data: context?.formData,
        },
        onCompleted: (data) => {
          next({ projectId: data?.updateProject?.id })
        },
        onError: (error) => {
          prev({
            errors: { _form: `Form.Error.${error.message}` },
          })
        },
      })
    } catch (e) {
      prev({ errors: { _form: 'Form.Error.ContactToOpeartor' } })
    }
  }, [])

  return <Containers.ProgressBar />
}

/**
 */
const RefetchStep = ({ next, context }) => {
  const {
    refetch: projectsRefetch,
  } = Project.Remote.Queries.MyEntries.useContext()
  const { refetch: projectRefetch } = Project.Remote.Queries.Entry.useContext()

  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    if (error) {
      window.location.href = Routes.Project.toSetting({
        project: context?.projectId,
      })
    }
  }, [error])

  useAsync(async () => {
    try {
      await projectsRefetch()
      await projectRefetch()

      next()
    } catch (error) {
      setError(error)
    }
  }, [])

  return <Containers.ProgressBar />
}

/**
 */
const BaseSubFlow = createFlow([ProgressStep, RefetchStep])

/**
 */
export const Default = ({ prev, next, context }) => {
  return (
    <Layout.Wrapper>
      <BaseSubFlow
        context={context}
        prev={prev}
        onComplete={() => {
          next()
        }}
      />
    </Layout.Wrapper>
  )
}
