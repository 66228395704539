import React from 'react'

import * as Project from '@arch-log/webapp.modules/project.v2/components'

/**
 */
export const InitCompleted = ({ next }) => {
  const [projectEntry] = Project.Scope.Entry.useEntry()

  React.useEffect(() => {
    if (projectEntry) {
      next({
        defaultValues: {
          ...projectEntry,
          buildingExtended: {
            ...projectEntry.buildingExtended,
            certifications: {
              ...projectEntry.buildingExtended?.certifications,
              otherCertifications: Object.assign(
                new Array(5).fill({}),
                projectEntry.buildingExtended?.certifications
                  ?.otherCertifications,
              ),
            },
            periodicInspections: Object.assign(
              new Array(5).fill({}),
              projectEntry.buildingExtended?.periodicInspections,
            ),
          },
          images: Object.assign(new Array(10).fill({}), projectEntry.images),
        },
      })
    }
  }, [projectEntry])

  return null
}
