import React from 'react'

import { useNavigate } from '@arch-log/webapp.lib/react.hook'
import { useProjectRoutes } from 'components/ProjectRoutes'

import * as ProjectAuthorization from '@arch-log/webapp.modules/project.authorization.v2/components'
import * as Permissions from '@arch-log/webapp.modules/project.authorization.v2/permissions'
import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'
import * as Errors from '@arch-log/webapp.shared/Project.v2/Errors'
import * as Partial from 'partials/ProjectEditPage'

/**
 */
export const Head = ({ pageContext }) => {
  return <PageHeadTemplates.Language language={pageContext?.language} />
}

/**
 */
const Page = () => {
  const { redirectTo } = useNavigate()

  const routes = useProjectRoutes()

  const hasPermisison = ProjectAuthorization.Remote.Queries.MyEntry.useHasPermissions()

  if (!hasPermisison(Permissions.Edit)) {
    return <Errors.ProjectNotPermission.Default />
  }

  return (
    <Partial.Flow
      onComplete={() => {
        redirectTo(routes.toSetting())
      }}
    />
  )
}

export default Page
