import React from 'react'

import * as Layout from './Layout'
import * as Containers from './Containers'

/**
 */
export const Default = ({ onComplete }) => {
  return (
    <Layout.Wrapper>
      <Layout.Body>
        <Containers.Message />
      </Layout.Body>
      <Layout.Footer>
        <Containers.CompleteButton onComplete={() => onComplete()} />
      </Layout.Footer>
    </Layout.Wrapper>
  )
}
